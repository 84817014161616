<template>
  <b-container>
    <b-card>
      <b-row>
        <b-col>
          <b-card>
            <b-card-body>
              <h2>PENILAIAN KINERJA & KONFIRMASI PERSETUJUAN</h2>
              <p>
                Berikut Formulir Penilaian Kinerja dan konfirmasi persetujuan
                Perjanjian Kerja Waktu Tertentu (PKWT) Pegawai PT. Griya Pondok
                Binasejati
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-body>
              <b-form>
                <b-form-group label="Email" label-cols-md="4">
                  <b-form-input type="text" v-model="data.email"></b-form-input>
                </b-form-group>
                <b-form-group label="Nama Penilai" label-cols-md="4">
                  <b-form-input
                    type="text"
                    v-model="data.namaPenilai"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Jabatan Penilai" label-cols-md="4">
                  <b-form-input
                    type="text"
                    v-model="data.jabatanPenilai"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Tempat Tugas" label-cols-md="4">
                  <b-form-input
                    type="text"
                    v-model="data.tempatTugas"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Tanggal Penilaian" label-cols-md="4">
                  <date-picker
                    style="width: 100%"
                    v-model="data.tanggalPenilaian"
                  ></date-picker>
                </b-form-group>
                <b-form-group label="Nama Karyawan" label-cols-md="4">
                  <b-form-input
                    type="text"
                    v-model="data.namaKaryawan"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Tugas Kerja Karyawan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.namaPosisi"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Aspek Kepribadian</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Kejujuran & Integritas dalam melakukan Pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian1"
                    :options="options1"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Penampilan, etika & kelengkapan atribut seragam"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian2"
                    :options="options2"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Tanggung Jawab & disiplin dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian3"
                    :options="options3"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kepatuhan dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian4"
                    :options="options4"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Sikap & motivasi dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian5"
                    :options="options5"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>

          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Aspek Komunikasi</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="kerjasama dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian6"
                    :options="options6"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Koordinasi & berkomunikasi yang efektif"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian7"
                    :options="options7"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan untuk berinteraksi & mengungkapkan ide"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian8"
                    :options="options8"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan dalam menerima instruksi yang diberikan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian9"
                    :options="options9"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan komunikasi yang baik untuk melayani pengguna jasa maupun rekan kerja"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian10"
                    :options="options10"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Teknis Pekerjaan</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Efektifitas & efisien si dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian11"
                    :options="options11"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Ketelitian dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian12"
                    :options="options12"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan mencapai target Standard Operation Procedure (SOP) Perusahaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian13"
                    :options="options13"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Inisiatif dalam menyelesaikan persoalan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian14"
                    :options="options14"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Antusias & sikap terhadap Pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian15"
                    :options="options15"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Aspek Produktivitas</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Kemmapuan dalam melakukan pekerjaan sendiri"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian16"
                    :options="options16"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kualitas, kerapian & keterampilan dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian17"
                    :options="options17"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Menunjukan minat meningkatkan pengetahuan yang baik untuk melakukan pekerjaan rutin / ekstra"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian18"
                    :options="options18"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Bertanggung jawab atas sarana & prasarana perlengkapan kerja yang dipercayakan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian19"
                    :options="options19"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kedisiplinan dalam kehadiran kerja serta disiplin dalam penggunaan waktu"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.penilaian20"
                    :options="options20"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Catatan Tentang Karyawan</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Catatan dapat diisi seputar rencana usulan promosi/mutasi, prestasi karyawan, catatan khusus serta kritik"
                  label-cols-md="4"
                >
                  <b-form-input
                    type="text"
                    v-model="data.catatanKhusus"
                  ></b-form-input>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Persetujuan Penandatanganan PKWT"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    v-model="data.persetujuanPenandatanganan"
                    :options="optionsSetuju"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Apabila setuju, berapa jangka waktu kerja yang diinginkan ?
"
                  label-cols-md="4"
                >
                  <b-form-input
                    type="number"
                    v-model="data.jangkaWaktuKerja"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card-footer>
        <b-row>
          <b-col>
            <center>
              <b-button variant="danger" @click="reset()"
                >Kosongkan Form</b-button
              >
            </center>
          </b-col>
          <b-col>
            <center>
              <b-button variant="success" @click="register" :disabled="isValid"
                >Kirim Penilaian</b-button
              >
            </center>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </b-container>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      data: {
        email: null,
        namaPenilai: null,
        jabatanPenilai: null,
        tempatTugas: null,
        tanggalPenilaian: null,
        catatanKhusus: null,
        persetujuanPenandatanganan: null,
        jangkaWaktuKerja: null,
        poin: null,
        namaKaryawan: null,
        namaPosisi: null,
        totalPoin: 0,
        penilaian1: 0,
        penilaian2: 0,
        penilaian3: 0,
        penilaian4: 0,
        penilaian5: 0,
        penilaian6: 0,
        penilaian7: 0,
        penilaian8: 0,
        penilaian9: 0,
        penilaian10: 0,
        penilaian11: 0,
        penilaian12: 0,
        penilaian13: 0,
        penilaian14: 0,
        penilaian15: 0,
        penilaian16: 0,
        penilaian17: 0,
        penilaian18: 0,
        penilaian19: 0,
        penilaian20: 0,
      },
      optionsSetuju: [
        { text: "Setuju", value: true },
        { text: "Tidak Setuju", value: false },
      ],
      options1: [
        { text: "Dapat dipercaya (3 Poin)", value: 3 },
        { text: "Memerlukan Pengawasan (2 Poin)", value: 2 },
        { text: "Sangat Memerlukan Pengawasan (1 Poin)", value: 1 },
      ],
      options2: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options3: [
        {
          text: "Melakukan pekerjaan dengan maksimal dan berusahan meningkatkan Performa (3 Poin)",
          value: 3,
        },
        { text: "Melakukan pekerjaan secara wajar (2 Poin)", value: 2 },
        {
          text: "Melakukan pekerjaan dengan tidak sungguh-sungguh (1 Poin)",
          value: 1,
        },
      ],
      options4: [
        { text: "Dapat dipercaya (3 Poin)", value: 3 },
        { text: "Memerlukan Pengawasan (2 Poin)", value: 2 },
        { text: "Sangat Memerlukan Pengawasan (1 Poin)", value: 1 },
      ],
      options5: [
        { text: "Antusias dalam melakukan pekerjaan (3 Poin)", value: 3 },
        { text: "Melaksanakan tugas secara wajar (2 Poin)", value: 2 },
        { text: "Acuh tak acuh dalam melakukan pekerjaan (1 Poin)", value: 1 },
      ],
      options6: [
        {
          text: "Selain mekakukan tugasnya, berusaha juga untuk membantu gaian / unit lain (3 Poin)",
          value: 3,
        },
        {
          text: "Dapat bekerjasama dengan bagian / unit lain secara wajar (2 Poin)",
          value: 2,
        },
        {
          text: "Tidak kooperatif dengan bagian / unit lain (1 Poin)",
          value: 1,
        },
      ],
      options7: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options8: [
        {
          text: "Berani memulai pembicaraan dan sering menyampaikan ide yang baik(3 Poin)",
          value: 3,
        },
        {
          text: "Berani memulai pembicaraaan dan terkadang menyampaikan ide yang baik (2 Poin)",
          value: 2,
        },
        {
          text: "Tidak ada kemauan & cenderung acuh tak acuh dengan lingkungan (1 Poin)",
          value: 1,
        },
      ],
      options9: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options10: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options11: [
        {
          text: "Menyelesaikan pekerjaan se-efisien mungkin, sehingga pekerjaan selesai lebih cepat (3 Poin)",
          value: 3,
        },
        {
          text: "Menyelesaikan pekerjaan dalam tempo yang wajar (2 Poin)",
          value: 2,
        },
        {
          text: "Menyelesaikan pekerjaan lebih lambat dari teman-teman yang tingkat pekerjaannya sama (1 Poin)",
          value: 1,
        },
      ],
      options12: [
        {
          text: "Pekerjaan dilakukan dengan teliti & berusaha mencegah hal yang mengakikabtkan kerugian (3 Poin)",
          value: 3,
        },
        {
          text: "Pekerjaan dilakukan dengan baik, terkadang terjadi sedikit kekurangan (2 Poin)",
          value: 2,
        },
        {
          text: "Pekerjaan dilakukan dengan tidak teliti dan menyebabkan kerugian (1 Poin)",
          value: 1,
        },
      ],
      options13: [
        { text: "Memenuhi SOP (3 Poin)", value: 3 },
        { text: "Dilakukan secara wajar (2 Poin)", value: 2 },
        { text: "Selalu dibawah SOP (1 Poin)", value: 1 },
      ],
      options14: [
        {
          text: "Cepat tanggap & kreatif dalam memenuhi kewajiban (3 Poin)",
          value: 3,
        },
        {
          text: "Kadang tidak memenuhi target tetapi usaha sudah dilakukan(2 Poin)",
          value: 2,
        },
        {
          text: "Selalu menghindar dari tugas & banyak alasan (1 Poin)",
          value: 1,
        },
      ],
      options15: [
        {
          text: "Antusias tanpa memperhatikan waktu untuk menyelesaikan pekerjaan (3 Poin)",
          value: 3,
        },
        {
          text: "Menerima & melaksanakan pekerjaan secara wajar (2 Poin)",
          value: 2,
        },
        {
          text: "Acuh tak acuh dalam melaksanakan pekerjaan , bekerja atas dasar terpaksa (1 Poin)",
          value: 1,
        },
      ],
      options16: [
        {
          text: "Semua pekerjaan diselesaikan sendiri tanpa perlu petunjuk & dapat dilepaskan sepenuhnya (3 Poin)",
          value: 3,
        },
        {
          text: "Umumnya pekerjaan diselesaikan sendiri, hanya beberapa hal masih memerlukan bantuan (2 Poin)",
          value: 2,
        },
        {
          text: "Pekerjaan tidak pernah dapat diselesaikan sendiri & selalu memerlukan petunjuk (1 Poin)",
          value: 1,
        },
      ],
      options17: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options18: [
        {
          text: "Antusias & ada kemajuan pekerjaan sering dihasilkan (3 Poin)",
          value: 3,
        },
        {
          text: "Terkadang ada usaha untuk memperbaiki cara kerja (2 Poin)",
          value: 2,
        },
        {
          text: "Tidak menunjukkan kemajuan dalam melakukan tugas (1 Poin)",
          value: 1,
        },
      ],
      options19: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options20: [
        { text: "Hadir lebih awal & disiplin (3 Poin)", value: 3 },
        { text: "Terkadang terlambat hadir kerja (2 Poin)", value: 2 },
        { text: "Sering ijin  karena urusan pribadi (1 Poin)", value: 1 },
      ],
      options: [
        { text: "Petugas Kebersihan", value: "Petugas Kebersihan" },
        { text: "Pengemudi", value: "Pengemudi" },
        { text: "Pesuruh", value: "Pesuruh" },
        { text: "Satpam", value: "Satpam" },
      ],
    };
  },
  mixins: [validationMixin],
  validations: {
    data: {
      email: {
        required,
      },
      namaPenilai: {
        required,
      },
      jabatanPenilai: {
        required,
      },
      tempatTugas: {
        required,
      },
      tanggalPenilaian: {
        required,
      },
      catatanKhusus: {
        required,
      },
      persetujuanPenandatanganan: {
        required,
      },
      jangkaWaktuKerja: {
        required,
      },
      poin: {
        required,
      },
      namaKaryawan: {
        required,
      },
      namaPosisi: {
        required,
      },
      totalPoin: {
        required,
      },
      penilaian1: {
        required,
      },
      penilaian2: {
        required,
      },
      penilaian3: {
        required,
      },
      penilaian4: {
        required,
      },
      penilaian5: {
        required,
      },
      penilaian6: {
        required,
      },
      penilaian7: {
        required,
      },
      penilaian8: {
        required,
      },
      penilaian9: {
        required,
      },
      penilaian10: {
        required,
      },
      penilaian11: {
        required,
      },
      penilaian12: {
        required,
      },
      penilaian13: {
        required,
      },
      penilaian14: {
        required,
      },
      penilaian15: {
        required,
      },
      penilaian16: {
        required,
      },
      penilaian17: {
        required,
      },
      penilaian18: {
        required,
      },
      penilaian19: {
        required,
      },
      penilaian20: {
        required,
      },
    },
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
    totalss() {
      let x =
        this.data.penilaian1 +
        this.data.penilaian2 +
        this.data.penilaian3 +
        this.data.penilaian4 +
        this.data.penilaian5 +
        this.data.penilaian6 +
        this.data.penilaian7 +
        this.data.penilaian8 +
        this.data.penilaian9 +
        this.data.penilaian10 +
        this.data.penilaian11 +
        this.data.penilaian12 +
        this.data.penilaian13 +
        this.data.penilaian14 +
        this.data.penilaian15 +
        this.data.penilaian16 +
        this.data.penilaian17 +
        this.data.penilaian18 +
        this.data.penilaian19 +
        this.data.penilaian20;
      return x;
    },
  },
  methods: {
    register() {
      let vm = this;
      vm.data.totalPoin = this.totalss;
      axios
        .post(ipBackend + "penilaianKinerja/register", this.data)
        .then((res) => {
          console.log(res);
          alert("sukses");
          this.$router.push({path:"/loker"})
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reset() {
      this.data = {
        email: null,
        namaPenilai: null,
        jabatanPenilai: null,
        tempatTugas: null,
        tanggalPenilaian: null,
        catatanKhusus: null,
        persetujuanPenandatanganan: null,
        jangkaWaktuKerja: null,
        poin: null,
        namaKaryawan: null,
        namaPosisi: null,
        totalPoin: 0,
        penilaian1: 0,
        penilaian2: 0,
        penilaian3: 0,
        penilaian4: 0,
        penilaian5: 0,
        penilaian6: 0,
        penilaian7: 0,
        penilaian8: 0,
        penilaian9: 0,
        penilaian10: 0,
        penilaian11: 0,
        penilaian12: 0,
        penilaian13: 0,
        penilaian14: 0,
        penilaian15: 0,
        penilaian16: 0,
        penilaian17: 0,
        penilaian18: 0,
        penilaian19: 0,
        penilaian20: 0,
      };
    },
  },
};
</script>